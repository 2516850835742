import { render, staticRenderFns } from "./Message.vue?vue&type=template&id=487b6c13&"
import script from "./Message.vue?vue&type=script&lang=js&"
export * from "./Message.vue?vue&type=script&lang=js&"
import style0 from "./Message.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2146161230/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('487b6c13')) {
      api.createRecord('487b6c13', component.options)
    } else {
      api.reload('487b6c13', component.options)
    }
    module.hot.accept("./Message.vue?vue&type=template&id=487b6c13&", function () {
      api.rerender('487b6c13', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/_Id/components/Message.vue"
export default component.exports