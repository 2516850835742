var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("q-separator", { attrs: { spaced: "" } }),
      _c(
        "q-item",
        [
          _c(
            "q-item-section",
            { staticClass: "col-4", attrs: { cencer: "" } },
            [
              _c("q-select", {
                attrs: {
                  "map-options": "",
                  "emit-value": "",
                  options: _vm.targetOptions,
                  label: _vm.$t("Select"),
                  borderless: "",
                  dense: "",
                  readonly: _vm.readonly,
                  "hide-dropdown-icon": _vm.readonly
                },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function(scope) {
                      return [
                        _c(
                          "q-item",
                          _vm._g(
                            _vm._b({}, "q-item", scope.itemProps, false),
                            scope.itemEvents
                          ),
                          [
                            _c(
                              "q-item-section",
                              [
                                _c("q-item-label", [
                                  _vm._v(_vm._s(_vm.$t(scope.opt.label)))
                                ]),
                                _c("q-item-label", { attrs: { caption: "" } }, [
                                  _vm._v(_vm._s(_vm.$t(scope.opt.description)))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.target.key,
                  callback: function($$v) {
                    _vm.$set(_vm.target, "key", $$v)
                  },
                  expression: "target.key"
                }
              })
            ],
            1
          ),
          _c("q-separator", { attrs: { vertical: "", spaced: "" } }),
          _c(
            "q-item-section",
            { attrs: { center: "" } },
            [
              _vm.showLocationSelect
                ? [
                    _c(
                      "a-radio-group",
                      {
                        attrs: { value: _vm.isFollowWebhook },
                        on: { change: _vm.changeFollowWebhook }
                      },
                      [
                        _c(
                          "a-radio",
                          { style: _vm.radioStyle, attrs: { value: true } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Follow webhook instructions")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-radio",
                          { style: _vm.radioStyle, attrs: { value: false } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "min-width": "80px" } },
                              [
                                _c("LocationSelect", {
                                  attrs: {
                                    readonly:
                                      _vm.isFollowWebhook || _vm.readonly,
                                    initialValue: _vm.target.value
                                  },
                                  on: { changed: _vm.onChangedTarget }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.showAppTarget
                ? _c("AppTarget", {
                    attrs: {
                      readonly: _vm.readonly,
                      initialValue: _vm.target.value
                    },
                    on: { changed: _vm.onChangedTarget }
                  })
                : _vm._e()
            ],
            2
          ),
          _c("q-item-section", { attrs: { center: "", side: "" } }, [
            _c(
              "div",
              { staticClass: "text-grey-8 q-gutter-xs" },
              [
                _vm.canAdd
                  ? _c("q-btn", {
                      attrs: {
                        flat: "",
                        color: "primary",
                        label: _vm.$t("and")
                      },
                      on: { click: _vm.add }
                    })
                  : _vm._e(),
                _vm.canRemove
                  ? _c("q-btn", {
                      staticClass: "gt-xs",
                      attrs: {
                        size: "12px",
                        flat: "",
                        dense: "",
                        round: "",
                        icon: "delete"
                      },
                      on: { click: _vm.remove }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }