//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    initialValue: null,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: null,
      options: [
        {
          value: ['univoice'],
          label: 'Uni-Voice'
        },
        {
          value: ['univoice_blind'],
          label: 'Uni-Voice Blind'
        }
      ]
    };
  },
  watch: {
    model(newValue) {
      this.$emit('changed', newValue);
    }
  },
  created() {
    this.model = this.initialValue;
  }
};
