export const messageMixin = {
  props: {
    pushMessage: {
      type: Object,
      required: true,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canSubmit() {
      if (this.isEdit) {
        const status = this.pushMessage.status;
        return status === 'REJECTED' || status === 'DRAFT';
      }
      return true;
    },
    canSave() {
      if (this.isEdit) {
        const status = this.pushMessage.status;
        return status === 'REJECTED' || status === 'DRAFT';
      }
      return true;
    }
  }
};
