var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-select", {
    attrs: {
      "map-options": "",
      "emit-value": "",
      readonly: _vm.readonly,
      "hide-dropdown-icon": _vm.readonly,
      options: _vm.options,
      label: _vm.$t("Select"),
      borderless: "",
      dense: ""
    },
    model: {
      value: _vm.model,
      callback: function($$v) {
        _vm.model = $$v
      },
      expression: "model"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }