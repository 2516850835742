//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LocationSelect from './LocationSelect';
import AppTarget from './AppTarget';

export default {
  components: {
    LocationSelect,
    AppTarget
  },
  props: {
    currentSize: {
      type: Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    initialTarget: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    targetOptions: {
      type: Array,
      required: true
    },
    isFollowWebhook: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radioStyle: {
        display: 'flex',
        alignItems: 'center',
        margin: '8px 0',
        minWidth: '200px'
      },
      target: {},
    }
  },
  watch: {
    'target.value'() {
      this.$emit('changed', this.target);
    },
    'target.key'() {
      this.target.value = null;
    }
  },
  computed: {
    canRemove() {
      if (this.readonly) {
        return false;
      }
      return true;
    },
    canAdd() {
      if (this.readonly) {
        return false;
      }

      if (!this.target.value) {
        return false;
      }

      if (this.index < this.currentSize - 1) {
        return false;
      }

      if (this.currentSize === this.targetOptions.length) {
        return false;
      }
      return true;
    },

    showLocationSelect() {
      return this.target && this.target.key === 'location';
    },
    showAppTarget() {
      return this.target && this.target.key === 'appTarget';
    }
  },
  created() {
    this.target = { ...this.initialTarget };
  },
  methods: {
    changeFollowWebhook({ target }) {
      this.$emit('followWebhook', target.value)
    },
    onChangedTarget(newValue) {
      this.target = {
        ...this.target,
        value: newValue
      };
    },

    remove() {
      this.$emit('remove', this.$vnode.key);
    },

    add() {
      this.$emit('add');
    }
  }
};
