var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-tree-select", {
    staticStyle: { width: "100%" },
    attrs: {
      "dropdown-style": { maxHeight: "400px", overflow: "auto" },
      "tree-data": _vm.treeData,
      "show-checked-strategy": _vm.SHOW_PARENT,
      treeNodeFilterProp: "title",
      placeholder: _vm.$t("Please select"),
      "show-search": "",
      "allow-clear": "",
      "tree-checkable": "",
      disabled: _vm.readonly
    },
    model: {
      value: _vm.model,
      callback: function($$v) {
        _vm.model = $$v
      },
      expression: "model"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }