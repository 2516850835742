//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Targeting from './Message/Targeting.vue';
import { mapGetters, mapState } from 'vuex';
import i18n from '@/locales';
import { timer } from 'rxjs';

export default {
  components: {
    Targeting
  },

  data() {
    return {
      targetMsg: i18n.t('Option'),
      activeTab: 'configuration', // conditions
      loading: false,
      fetching: false,
      isEdit: false,
      form: this.$form.createForm(this, { name: 'compose-auto-push-setting' }),

      auto_push: {
        availability: false,
        enabled: false,
        conditions: [
          {
            enabled: true,
            project_id: 0,
            or: [
              {
                and: [
                  {
                    field: 'category_name',
                    operator: 'equal_to',
                    value: ''
                  }
                ]
              }
            ]
          }
        ],
        config: {
          push_after: 0
          // app_target: [],
          // filter_prefecture: [],
          // filter_city: [],
          // filter_ward: []
        }
      },
      conditionFields: [
        {
          label: 'Category name',
          value: 'category_name'
        },
        {
          label: 'Tags',
          value: 'tags'
        }
      ],
      conditionTypes: {
        category_name: 'String',
        tags: 'Array'
      },
      conditionOperators: {
        category_name: [
          {
            label: 'Equal to',
            value: 'equal_to'
          },
          {
            label: 'Similar to',
            value: 'similar_to'
          }
        ],
        tags: [
          {
            label: 'Contains',
            value: 'contains'
          }
        ]
      },
      pageOpts: [],
      pageOptsObj: null,
      done: {
        targeting: false
      },
      errors: [],
      numberValidate: {
        push_after: {
          value: 0
        }
      },
      addPageCondition: {
        visible: false,
        item: {
          project_id: null,
          and: []
        }
      }
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById']),
    ...mapState('user', ['setting']),

    companyId() {
      return this.userInfo.company_id;
    },

    project() {
      return this.projectById(this.$route.params.projectId);
    },
    formItemLayout() {
      return {
        labelCol: { sm: { span: 8 }, lg: { span: 3 }, xl: { span: 3 } },
        wrapperCol: { sm: { span: 16 }, lg: { span: 21 }, xl: { span: 21 } }
      };
    },
    formTailLayout() {
      return {
        labelCol: { sm: { span: 8 }, lg: { span: 2 }, xl: { span: 2 } },
        wrapperCol: {
          sm: { span: 16, offset: 8 },
          lg: { span: 16, offset: 3 },
          xl: { span: 16, offset: 3 }
        }
      };
    },
    autoPushConditions() {
      return this.auto_push?.conditions || [];
    },
    autoPushConfig() {
      return this.auto_push?.config || {};
    },
    numberValidateStatus() {
      const { push_after } = this.numberValidate;
      if (push_after.value < this.setting.auto_sending) {
        push_after.validateStatus = 'error';
      } else push_after.validateStatus = 'success';
      return { push_after };
    },
    numberHelperText() {
      return {
        push_after: this.$t('format.autoPushAfter', {
          minSending: this.setting.auto_sending
        })
      };
    },
    defaultAutoPush() {
      return {
        availability: false,
        enabled: false,
        conditions: [
          {
            enabled: true,
            project_id: 0,
            or: [
              {
                and: [
                  {
                    field: 'category_name',
                    operator: 'equal_to',
                    value: ''
                  }
                ]
              }
            ]
          }
        ],
        config: {
          push_after: 0
          // app_target: [],
          // filter_prefecture: [],
          // filter_city: [],
          // filter_ward: []
        }
      };
    }
  },

  created() {
    this.initial();
  },
  watch: {
    setting: {
      handler: function (val) {
        if (this.numberValidate.push_after.value < val.auto_sending) {
          this.numberValidate.push_after.value = val.auto_sending;
          this.auto_push.config.push_after = val.auto_sending;
        }
      },
      immediate: true
    }
  },
  methods: {
    async initial() {
      this.auto_push = this.project?.message?.auto_push || this.defaultAutoPush;
      this.auto_push = {
        ...this.auto_push,
        conditions: this.auto_push.conditions || this.defaultAutoPush.conditions,
        config: this.auto_push.config || this.defaultAutoPush.config
      };
      this.numberValidate.push_after = {
        value: this.auto_push.config.push_after || 0
      };
      // Get pages
      this.pageOpts = await this.$s.api.page.find(this.project.id, {
        filter: {
          where: {
            template: 'CATALOG',
            status: 'ACTIVE'
          },
          order: 'order_by DESC'
        }
      });
      this.pageOptsObj = this.pageOpts.reduce((acc, page) => {
        return { ...acc, [page.id]: page };
      }, {});
      // filter if page condition is not exist in pageOpts
      this.auto_push.conditions = this.auto_push.conditions.filter(condition =>
        this.pageOpts.some(page => page.id === condition.project_id)
      );
    },
    onChangeTab(tab) {
      this.activeTab = tab;
    },

    async validateTargetPayload() {
      let isFollowWebhook = false;
      let targetData = [];
      try {
        if (this.$refs.refTargeting) {
          const data = await this.$refs.refTargeting.validate()
          isFollowWebhook = data.isFollowWebhook
          targetData = data.targets
        }
      } catch (error) {
        console.log(error);
        // VAlidate in local
        if (error.type) {
          this.done[error.type] = true;
          this.errors = error.errors;
          this.$subscribeTo(timer(5000), () => {
            this.done[error.type] = false;
            this.errors = [];
          });
        }
        // Error from server
        if (error.code) {
          this.$message.error(this.$t(error.message), 3);
        }
        throw new Error(this.$t('Invalid target data'));
      }
      const targetOptions = targetData.reduce((acc, option) => {
        if (option.key === 'age') {
          return {
            ...acc,
            filter_max_age: option.value.max,
            filter_min_age: option.value.min
          };
        }
        if (option.key === 'location') {
          return {
            ...acc,
            ...option.value
          };
        }
        if (option.key === 'appTarget') {
          return {
            ...acc,
            app_target: option.value
          };
        }
        if (option.key === 'gender') {
          return {
            ...acc,
            filter_gender: option.value
          };
        }
      }, {});
      const payload = {
        is_follow_webhook: isFollowWebhook,
        targetOptions: targetOptions
      };
      console.log('payload: ', payload)

      if (payload?.targetOptions?.advance) {
        delete payload.targetOptions.advance;
      }
      return payload;
    },
    async handleSaveProjectMessageConfig() {
      this.loading = true;
      try {
        const { is_follow_webhook, targetOptions } = await this.validateTargetPayload();
        this.validateMessConfigPayload();
        const autoPushPayload = {
          ...this.auto_push,
          is_follow_webhook,
          config: {
            ...targetOptions,
            push_after: this.numberValidateStatus?.push_after?.value
          }
        };
        await this.$s.api.project.editProjectMessageConfigById(this.project.id, {
          auto_push: autoPushPayload
        });
        this.auto_push = autoPushPayload;
        this.$notification.success({ message: this.$t('This project has been updated') });
      } catch (error) {
        console.log('error', error.message);
        this.$message.error(error.message);
      }
      this.loading = false;
    },
    validateMessConfigPayload() {
      const { push_after } = this.numberValidateStatus;
      if (push_after.validateStatus === 'error')
        throw new Error(this.$t('Invalid schedule configuration'));
      // validate page condition
      this.auto_push.conditions.forEach(condition => {
        if (condition.or.length === 0) throw new Error(this.$t('Invalid page condition'));
        condition.or.forEach(orCondition => {
          if (orCondition.and.length === 0) throw new Error(this.$t('Invalid page condition'));
          orCondition.and.forEach(andCondition => {
            if (!andCondition.value) throw new Error(this.$t('Invalid page condition'));
          });
        });
      });
    },
    handleAddPageCondition() {
      if (!this.addPageCondition.item.project_id) return;
      this.auto_push.conditions.unshift({
        enabled: true,
        project_id: this.addPageCondition.item.project_id,
        or: [
          {
            and: []
          }
        ]
      });
      this.addPageCondition.visible = false;
      this.addPageCondition.item = {};
    },
    handleDeletePageCondition(projectId) {
      this.auto_push.conditions = this.auto_push.conditions.filter(
        condition => condition.project_id !== projectId
      );
    },
    handleAddPageConditionOr(projectId) {
      this.auto_push.conditions = this.auto_push.conditions.map(condition => {
        if (condition.project_id === projectId) {
          condition.or.push({
            and: []
          });
        }
        return condition;
      });
    },
    handleRemovePageConditionOr(projectId, orIndex) {
      this.auto_push.conditions = this.auto_push.conditions.map(condition => {
        if (condition.project_id === projectId) {
          condition.or.splice(orIndex, 1);
        }
        return condition;
      });
    },
    handleChangePageConditionAnd(projectId, orIndex, andIndex, payload) {
      const { key, value } = payload;
      this.auto_push.conditions = this.auto_push.conditions.map(condition => {
        if (condition.project_id === projectId) {
          condition.or[orIndex].and[andIndex][key] = value;
          if (key === 'field')
            condition.or[orIndex].and[andIndex].operator = this.conditionOperators[value][0].value;
        }
        return condition;
      });
    },
    handleAddPageConditionAnd(projectId, orIndex) {
      this.auto_push.conditions = this.auto_push.conditions.map(condition => {
        if (condition.project_id === projectId) {
          for (let i = 0; i < this.conditionFields.length; i++) {
            if (
              !condition.or[orIndex].and.some(item => item.field === this.conditionFields[i].value)
            ) {
              condition.or[orIndex].and.push({
                field: this.conditionFields[i].value,
                operator: this.conditionOperators[this.conditionFields[i].value][0].value,
                value: ''
              });
              break;
            }
          }
        }
        return condition;
      });
    },
    handleRemovePageConditionAnd(projectId, orIndex, andIndex) {
      this.auto_push.conditions = this.auto_push.conditions.map(condition => {
        if (condition.project_id === projectId) {
          condition.or[orIndex].and.splice(andIndex, 1);
        }
        return condition;
      });
    },

    handleChangeNumberInput(key, value) {
      this.numberValidate[key].value = value;
      this.auto_push.config[key] = value;
    },
    handleGoTo(path) {
      this.$router.push(path);
    }
  }
};
