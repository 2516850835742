var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    [
      _c(
        "a-form-item",
        _vm._b(
          { attrs: { label: " ", colon: false } },
          "a-form-item",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "q-list",
            { staticClass: "rounded-borders", attrs: { bordered: "" } },
            [
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    { attrs: { avatar: "", top: "" } },
                    [
                      _c("q-icon", {
                        attrs: {
                          name: "account_tree",
                          color: "black",
                          size: "34px"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    { staticClass: "col-8", attrs: { center: "" } },
                    [
                      _c("q-item-label", { staticClass: "q-mt-sm" }, [
                        _vm._v(" " + _vm._s(_vm.$t("Target Users")))
                      ])
                    ],
                    1
                  ),
                  _c("q-space"),
                  _c("q-item-section", { attrs: { center: "", side: "" } }, [
                    _c(
                      "div",
                      { staticClass: "text-grey-8 q-gutter-xs" },
                      [
                        !_vm.targets.length
                          ? _c("q-btn", {
                              attrs: {
                                flat: "",
                                color: "primary",
                                disable: _vm.isDisabled,
                                label: _vm.$t("Add Filter")
                              },
                              on: { click: _vm.addFilter }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm._l(_vm.targets, function(target, index) {
                return _c("TargetOption", {
                  key: target.id,
                  attrs: {
                    index: index,
                    targetOptions: _vm.targetOptions(target),
                    "current-size": _vm.targets.length,
                    readonly: _vm.isDisabled,
                    isFollowWebhook: _vm.isFollowWebhook,
                    initialTarget: target
                  },
                  on: {
                    remove: _vm.remove,
                    add: _vm.add,
                    changed: _vm.onChangedTarget,
                    followWebhook: _vm.changeFollowWebhook
                  }
                })
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }